/// <reference path="./SubmitPersonSignificantControl.ts" />

module Shared {
    // $Classes/Enums/Interfaces(filter)[template][separator]
    // filter (optional): Matches the name or full name of the current item. * = match any, wrap in [] to match attributes or prefix with : to match interfaces or base classes.
    // template: The template to repeat for each matched item
    // separator (optional): A separator template that is placed between all templates e.g. $Properties[public $name: $Type][, ]

    // More info: http://frhagn.github.io/Typewriter/

    
    export class NavPersonalInformation  {
        
        // JOBTITLE
        public jobTitle: string = null;
        // TITLE
        public title: string = null;
        // FIRSTNAME
        public firstName: string = null;
        // MIDDLENAME
        public middleName: string = null;
        // SURNAME
        public surname: string = null;
        // FILINGCODE
        public filingCode: string = null;
        // HOMEPHONENO
        public homePhoneNo: string = null;
        // MOBILEPHONENO
        public mobilePhoneNo: string = null;
        // WORKPHONENO
        public workPhoneNo: string = null;
        // EMAIL
        public eMail: string = null;
        // SMSALERTPREFERENCE
        public smsAlertPreference: number = 0;
        // EMAILPAYSLIPSPREFERENCE
        public emailPayslipsPreference: number = 0;
        // PREFERREDMETHODOFCONTACT
        public preferredMethodOfContact: string = null;
        // ADDRESS
        public address: string = null;
        // ADDRESS2
        public address2: string = null;
        // CITY
        public city: string = null;
        // COUNTY
        public county: string = null;
        // POSTCODE
        public postcode: string = null;
        // PASSWORD
        public password: string = null;
        // CONFIRMPASSWORD
        public confirmPassword: string = null;
        // DOB
        public dob: Date = null;
        // NINUMBER
        public niNumber: string = null;
        // UTRNUMBER
        public utrNumber: string = null;
        // NATIONALITY
        public nationality: string = null;
        // NATIONALITYOPTION
        public nationalityOption: NAVNationality = null;
        //DOSUBMITPSC
        public doSubmitPSC: SubmitPersonSignificantControl = null;
        // Contact Joining Date
        public contactJoiningDate: Date = null;
        // Contract File Name
        public contractFileName: string = null;
        public tfaEnabled: boolean = false;
        public tfaEnabledAmended: boolean = false;
        public twoFactorDisabledExpiryDate: Date = null;
        public twoFactorOverrideDate: Date = null;
    }
}
